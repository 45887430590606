




.bg-banner {
  width: 100%;
  height: 100vh;
}

.bg-banner::after {
  content: "";
  position: absolute;
  background: url(https://appychatbot.pbodev.info/wp-content/uploads/2022/11/bg-img-chatbot.png) no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
}

/* Design change css */
.content-wraper {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 1200px;
  transform: translateY(-50%);
  text-align: center;
}
.content-wraper ul {
  flex-wrap: wrap;
}
.content-wraper ul li {
  box-shadow: 0px 0px 10px #0000000a;
  border-radius: 10px;
  padding: 15px;
  width: calc(33.33% - 20px);
  margin: 10px;
  text-align: left;
  background-color: #fff;
}
.content-wraper ul li img {
  width: 50px;
  margin-right: 10px;
}
.content-wraper ul li h5 {
  font-size: 16px;
}
.content-wraper ul li p {
  font-size: 15px;
  margin-bottom: 0;
}
.content-wraper ul li:hover {
  outline: auto;
  outline-color: #3EBCFF;
}
.content-wraper ul li.active{
  outline: auto;
  outline-color: #3EBCFF;
}


@media (max-width:1024px) {
  .content-wraper ul li{
    width: calc(50% - 20px);
  }
}
@media (max-width:767px) {
  .content-wraper ul li{
    width: calc(100% - 40px);
    margin: 10px 25px;
  }
}







.content-wraper .type-name {
  display: block;
  max-width: 400px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
  outline: none;
  margin: 0px auto 30px;
}

.btn-primary.save-btn {
  background: #2680EB;
  color: #fff !important;
  border-radius: 41px;
  padding: 10px 50px;
  font-size: 18px;
}

.check-label {
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.check-label .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50%;
}

.check-label:hover input~.checkmark {
  background-color: #ccc;
}

.check-label input:checked~.checkmark {
  background-color: #2680EB;
}

.check-label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-label input:checked~.checkmark:after {
  display: block;
}

.check-label .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
#ErrorMsg {
  margin: 0;
  position: absolute;
  bottom: -20px;
  left: 20px;
  font-size: 12px;
  text-align: left;
  right: 0;
  width: 400px;
  margin: 0 auto;
  color: #f00;
}

/* Livechat screen css */

.mobileFront {
  max-width: 350px;
  width: 100%;
  box-shadow: 0px 0px 11px 3px rgb(128 128 128 / 20%);
  border-radius: 15px;
  margin-left: auto;
}


.header {
  padding: 20px 20px;
  border-radius: 15px 15px 0px 0px;
  background: linear-gradient(90deg, rgba(43, 42, 229, 1) 0%, rgba(9, 9, 121, 1) 0%, rgba(8, 70, 176, 1) 26%, rgba(7, 154, 251, 1) 100%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileLeft img.profile {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.profileRight {
  margin-left: auto;
}

.profileRight img {
  width: 16px;
  cursor: pointer;
}

.profileLeft {
  display: flex;
  align-items: center;

}

.headerBottomTab {
  background: linear-gradient(90deg, rgba(37, 61, 223, 1) 0%, rgba(7, 193, 253, 1) 100%);
  padding: 10px 20px;
  color: #fff;
  display: flex;
  align-items: center;
}

.headerBottomTab .online {
  background: #56b348;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.chatMessage {
  background: #fff;
  padding: 10px 10px;
  max-height: 258px;
  overflow: auto;
}

.messageAll .recived p {
  display: inline-block;
  background: #f0f2f7;
  padding: 10px 10px;
  text-align: left;
  border-radius: 8px;
  color: #535353;
  max-width: calc(100% - 15%);
}

.messageAll .send p {
  display: inline-block;
  background: linear-gradient(90deg, rgba(43, 42, 229, 1) 0%, rgba(7, 150, 250, 1) 100%);
  padding: 10px 10px;
  text-align: left;
  border-radius: 8px;
  color: #fff;
  max-width: calc(100% - 15%);
  margin-left: 15%;
  margin-bottom: 5px;
}

.chatMessage .messageAll {
  margin-bottom: 10px;
}

.chatenterMessage {
  padding: 10px;
  background: #fff;
  position: relative;
}

.chatenterMessage input,
.chatenterMessage input:focus,
.chatenterMessage input:active {
  border: 0;
  border-top: 1px solid #abb5c6;
  width: 100%;
  padding: 10px 0px;
  font-size: 14px;
  color: #abb5c6;
  outline: 0;
  box-shadow: inherit;
}

.chatenterAction ul li {
  list-style: none;
  display: inline-block;
  padding-right: 10px;
}


.chatenterAction {
  background: #fff;
  padding: 0px 10px 20px 10px;
  border-radius: 0px 0px 15px 15px;
}

.msg-fire {
  width: 40px;
  height: 40px;
  background: linear-gradient(90deg, rgba(43, 42, 229, 1) 0%, rgba(9, 9, 121, 1) 0%, rgba(8, 70, 176, 1) 26%, rgba(7, 154, 251, 1) 100%);
  color: #fff;
  position: absolute;
  right: 10px;
  top: 20px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 35px;
  margin: 0 auto;
}
.sendBtn {
  width: 17px;
  height: 15px;
  margin-left: 5px;
}
.widget-view {
  position: absolute;
  bottom: 0;
  top: auto;
  right: -60px;
  width: 50px;
  height: 50px;
  line-height: 45px;
}
.widget-view img {
  width: 22px;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
}

.bg-banner {
  width: 100%;
  height: 100vh;
}

.bg-banner::after {
  content: "";
  position: absolute;
  background: url(https://appychatbot.pbodev.info/wp-content/uploads/2022/11/bg-img-chatbot.png) no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
}

.chatenterAction ul li a img {
  width: 35px;
  height: 20px;
}



.btn-primary.save-btn {
  background: #2680EB;
  border-radius: 41px;
  padding: 10px 50px;
  font-size: 18px;
}

.chatenterAction ul li .line {
  position: relative;
  display: inline-block;
}

.chatenterAction ul li .line::after {
  content: "";
  position: absolute;
  background-color: #acb5c6;
  width: 1px;
  height: 25px;
  top: -14px;
  right: -12px;
}

.select-bot .bot-head {
  position: relative;
  z-index: 1;
  justify-content: center;
  text-align: center;
}

.select-bot .bot-head .back {
  position: absolute;
  top: 30px;
  cursor: pointer;
  left: 10px;
  width: 30px;
}

.customize-view .customize-options,
.mobile-screen {
  width: 50%;
}

.mobile-screen {
  position: relative;
}

.mobile-screen::before {
  content: "";
  position: absolute;
  background: #c6c6c6;
  width: 1px;
  height: 95%;
  top: 50%;
  transform: translateY(-50%);
}

.customize-options .widget-box {
  background: #fff;
  box-shadow: 0px 0px 10px #0000000a;
  border-radius: 13px;
  padding: 20px;
  width: 340px;
}

.widget-box ul li {
  padding-right: 30px;
  cursor: pointer;
}

.widget-box ul li a {
  width: 40px;
  height: 40px;
  background-color: #E4E9F9;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 37px;
}

.widget-box ul li a.active {
  background: #2680EB;
}

.widget-box ul li a img {
  width: 20px;
}


.widget-box.thn-color ul li {
  padding-bottom: 20px;
}

.widget-box.thn-color ul li:nth-child(n + 5) {
  padding-bottom: 0px;
}

.widget-box.thn-color ul li a {
  background-color: #2580ea;
  height: 30px;
  line-height: 28px;
  position: relative;
  width: 30px;
  z-index: 1;
}

.widget-box.thn-color ul li a img {
  width: 16px;
}

.widget-box.thn-color ul li a.color-1 {
  background-color: #733ee5;
}

.widget-box.thn-color ul li a.color-2 {
  background-color: #ff6766;
}

.widget-box.thn-color ul li a.color-3 {
  background-color: #fb8446;
}

.widget-box.thn-color ul li a.color-4 {
  background-color: #fb8446;
}

.widget-box.thn-color ul li a.color-5 {
  background-color: #06c7fe;
}

.widget-box.thn-color ul li a.color-6 {
  background-color: #c1e7f7;
}

.widget-box.thn-color ul li a.color-7 {
  background-color: #09dce4;
}

.widget-box.thn-color ul li a.color-8 {
  background-color: #45be58;
}

.widget-box.thn-color ul li a.color-9 {
  background-color: #bcedbc;
}

.widget-box p,
.widget-box.thn-color p {
  font-size: 14px;
}

.timezone p {
  font-size: 14px;
}

.timezone .time-option select {
  border: 1px solid #BFBFBF;
  height: 40px;
  border-radius: 7px;
  outline: none;
  padding: 0px 10px;
  appearance: none;
  width: 200px;
  position: relative;
  background: rgb(224 224 224 / 30%);
  padding-right: 42px;
}

.timezone .time-option {
  width: 200px;
  position: relative;
}

.timezone .time-option::after {
  content: "";
  position: absolute;
  background: #BFBFBF;
  width: 1px;
  height: 25px;
  right: 40px;
  top: 7px;
}

.timezone .time-option img {
  position: absolute;
  right: 12px;
  top: 15px;
  width: 18px;
  z-index: 0;
}

.dot-menu {
  background: #fff;
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  margin-right: 12px;
  cursor: pointer;
}

.dot-menu::after,
.dot-menu::before {
  content: "";
  position: absolute;
  background: #fff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: -8px;
}

.dot-menu::after {
  bottom: -8px;
  top: auto;
}


/* Chatbot steps page */
ul,
      li {
         list-style: none;
         margin: 0;
         padding: 0;
      }

      a,
      a:hover {
         text-decoration: none;
      }

      .bg-banner {
         width: 100%;
         height: 100vh;
      }

      .bg-banner::after {
         content: "";
         position: absolute;
         background: url(https://appychatbot.pbodev.info/wp-content/uploads/2022/11/bg-img-chatbot.png) no-repeat center;
         background-size: 100% 100%;
         width: 100%;
         height: 100%;
         top: 0;
         z-index: -1;
      }

      .select-bot .bot-head {
         position: relative;
         z-index: 1;
         justify-content: center;
      }

      .select-bot .bot-head .back, .centerLavelSteps2 .back {
         position: absolute;
         top: 30px;
         cursor: pointer;
         left: 10px;
         width: 30px;
      }

      .bot-type ul li {
         background: #fff;
         padding: 25px 15px;
         margin: 10px;
         border-radius: 20px;
         box-shadow: 0px 0px 4px #00000021;
         width: calc(33.33% - 20px);
         margin-bottom: 30px;
         border: 1px solid transparent;
      }

      .bot-type ul li:hover {
         border-color: #3EBCFF;
         box-shadow: 0px 0px 10px #00000040;
      }

      .bot-type ul li a img {
         margin-right: 10px;
         border-radius: 20px;
         width: 50px;
      }

      .bot-type ul li a .bot-content {
         width: calc(100% - 65px);
      }

      .bot-type ul li a {
         color: #000;
      }

      .bot-type ul li a .bot-content p {
         font-size: 12px;
      }

      .btn-primary.save-btn {
         background: #2680EB;
         border-radius: 41px;
         padding: 10px 50px;
         font-size: 18px;
      }
  


      /* chatbot page css */

      
.serchFeilds input[type="text"] {
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 100px;
  padding: 10px 10px 10px 50px;
  font-size: 14px;
  border: 1px solid #e8e8e8;
  position: relative;
  opacity: 1;
  height: auto;
  width: 100%;
  cursor: text;
  z-index: 1;
}

.serchFeilds {
  position: relative;
  width: 100%;
  margin: 0px;
  margin-bottom: 20px !important;
  padding-right: 20px;
}

.serchFeilds span.serchIconC {
  position: absolute;
  left: 15px;
  top: 12px;
  z-index: 2;
}
.wrapperStep.botDesign {
  padding-bottom: 100px;
}

.allbotlist.scrollDesign {
  overflow-y: auto;
  padding: 0px;
  max-height: calc(100vh - 400px);
  overflow-x: hidden;
  min-height: 280px;
  padding-right: 10px;
  gap: 15px;
  margin-bottom: 20px;
}
.swiper-wrapper.allbotlist.scrollDesign {
  overflow-y: inherit;
  max-height: inherit;
  overflow-x: auto;
}

.scrollDesign::-webkit-scrollbar, .listChange.train-bot-body .alternative-ques::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 50px;
}
.allbotlist.scrollDesign::-webkit-scrollbar-thumb, .listChange.train-bot-body .alternative-ques::-webkit-scrollbar-thumb {
  background-color: #0483f8;
  border-radius: 10px;
}

.allbotlist.scrollDesign::-moz-scrollbar-thumb, .listChange.train-bot-body .alternative-ques::-moz-scrollbar-thumb {
  background-color: #0483f8;
}
.selct-bot-row.botTypeDesign.active {
  color: #000 !important;
  transition: all 0.3s ease-in-out;
  background: #d0e0f4;
  border: 1px solid #4092FB !important;
}

.selct-bot-row.botTypeDesign h4.BotTypeNameTitle{
  font-size: 16px;
  margin: 0;
  color: #000000;
  padding-top: 2px;
}
.selct-bot-row.botTypeDesign.active h4.BotTypeNameTitle{
  color: #000 !important;
}
.selct-bot-row.botTypeDesign .imageLogoIcon {
  width: 50px;
  margin: 0;
  height: 50px !important;
}
.selectYourBot .selct-bot-row.botTypeDesign .imageLogoIcon img {
  height: 40px;
  width: 35px;
}
.selct-bot-row.botTypeDesign {
  padding: 10px;
  border: 1px solid transparent;
  align-items: center;
  margin: 0;
}
.selct-bot-row.botTypeDesign.active {
  color: #000 !important;
  transition: all 0.3s ease-in-out;
  background: #d0e0f4;
  border: 1px solid #4092FB !important;
}

.selct-bot-row.botTypeDesign h4.BotTypeNameTitle{
  font-size: 16px;
  margin: 0;
  color: #000000;
  padding-top: 2px;
}
.selct-bot-row.botTypeDesign.active h4.BotTypeNameTitle{
  color: #000 !important;
}
.wrapperStep .selct-bot-row.botTypeDesign [data-track~="livechat"] .imageLogoIcon img {
  max-width: 30px;
  margin-top: 22px;
  margin-bottom: 8px;
}
.selct-bot-row.botTypeDesign .imageLogoIcon img {
  height: auto;
  width: 30px;
}
.selct-bot-row {
  cursor: pointer;
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  box-shadow: 0px 0px 6px #00000017;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 100%;
  transition: all 0.3s ease-in-out;
  display: flex;
  text-align: left;
}
.allbotlist .selectYourBot {
  width: calc(100% - 16px);
  margin: 8px;
  padding: 0px 0px;
  float: left;
}
.widgetNewAccordingSelction {
  width: 381px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #0000002e;
  border: none;
}
.centerLavelSteps2 .step1TitleHeadings {
  display: block !important;
}
.footerNext {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background: #FAFBFF;
  padding: 15px 10px;
  box-shadow: 0px 0px 7px 2px lightgrey;
  z-index: 999;
}
.container {
  margin: auto;
  width: 100%;
}

.align-items-center {
  align-items: center !important;
}
.d-flex {
  display: flex !important;
}


.cancelStep1 {
  color: #20303C;
  background: transparent;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.footerNext .startCustmisingStep1 {
  padding: 13px 40px;
  font-size: 20px;
}
.startCustmisingStep1 {
  background: #1B85FC;
  border: 1px solid #1B85FC;
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
}
ul,
li {
   list-style: none;
   margin: 0;
   padding: 0;
}

a,
a:hover {
   text-decoration: none;
}

.bg-banner {
   width: 100%;
   height: 100vh;
}

.bg-banner::after {
   content: "";
   position: absolute;
   background: url(https://appychatbot.pbodev.info/wp-content/uploads/2022/11/bg-img-chatbot.png) no-repeat center;
   background-size: 100% 100%;
   width: 100%;
   height: 100%;
   top: 0;
   z-index: -1;
}

.select-bot .bot-head {
   position: relative;
   z-index: 1;
   justify-content: center;
}

.select-bot .bot-head .back {
   position: absolute;
   top: 30px;
   cursor: pointer;
   left: 10px;
   width: 30px;
}

.bot-type ul li {
   background: #fff;
   padding: 25px 15px;
   margin: 10px;
   border-radius: 20px;
   box-shadow: 0px 0px 4px #00000021;
   width: calc(33.33% - 20px);
   margin-bottom: 30px;
   border: 1px solid transparent;
}

.bot-type ul li:hover {
   border-color: #3EBCFF;
   box-shadow: 0px 0px 10px #00000040;
}

.bot-type ul li a img {
   margin-right: 10px;
   border-radius: 20px;
   width: 50px;
}

.bot-type ul li a .bot-content {
   width: calc(100% - 65px);
}

.bot-type ul li a {
   color: #000;
}

.bot-type ul li a .bot-content p {
   font-size: 12px;
}

.btn-primary.save-btn {
   background: #2680EB;
   border-radius: 41px;
   padding: 10px 50px;
   font-size: 18px;
}


/* Popup css */
.botPop {
   top: 0;
   position: fixed;
   width: 100%;
   height: 100%;
   background: rgb(0 0 0 / 60%);
   z-index: 999;
}

.botPop .popBody {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background: #fff;
   padding: 30px;
   max-width: 570px;
   width: 100%;
   border-radius: 20px;
   text-align: center;
   left: 0;
   right: 0;
   margin: 0 auto;
}

.botPop .popBody img {
   width: 300px;
   margin-bottom: 30px;
}

.botPop .popBody span {
   color: #5D5D5D;
   font-size: 14px;
}

.botPop .popBody h3 {
   font-size: 30px;
   margin-bottom: 20px;
}

.botPop .popBody p {
   font-size: 18px;
}

.btn.btn-primary.save-btn.popbtn {
   background: #3EBCFF;
   border-color: #3EBCFF;
   border-radius: 9px;
   font-size: 16px;
   width: 157px;
   padding: 10px 7px;
   margin-right: 10px;
}

.btn.btn-primary.save-btn.popbtn.pop-gray {
   background: #A7A7A7;
   border-color: #A7A7A7;
   width: 184px;
   padding: 10px 7px;
}

.btn-section {
   max-width: 350px;
   width: 100%;
   margin: 0 auto;
}
.createChatBoatStepBy h2.stepHeading {
  padding: 0px;
  text-align: center;
  font-weight: normal;
  margin-top: 0px;
  font-size: 45px;
  color: #20303c;
  font-family: 'HelveticaNeueThin';
  margin-top: 5px !important;
}
.createChatBoatStepBy .centerLavelSteps2 h2.stepHeading {
  font-size: 45px !important;
}
.createChatBoatStepBy h3.text-center {
  font-size: 12px;
  color: #20303C;
  font-weight: 100;
  font-family: "HelveticaNeueThin";
}
.logoSectioneasyStep>a>img {
  width: 235px;
  height: auto;
  margin: 10px 0px;
}
.swiperFormSection .wrapperStep>div {
  position: relative;
  top: 20px;
  margin: 5px auto 20px !important;
}
.starAnimaton {
  width: 60px;
  position: absolute;
  height: 40px;
  top: 5px;
  right: 15px;
}
.starA {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0em;
  margin-bottom: 1.2em;
  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #df8d33;
  border-left: 0.3em solid transparent;
  font-size: 16px;
  transform: scale(0.4);
  animation: star 0.5s ease infinite alternate;
}
.starAnimaton i:nth-child(1) {
  position: absolute;
  top: 0;
  left: 15px;
}
.starAnimaton i:nth-child(2) {
  position: absolute;
  right: 0;
  top: 0;
  animation-delay: 0.5s;
}
.starAnimaton i:nth-child(3) {
  position: absolute;
  bottom: -12px;
  right: 2px;
  animation-delay: 1s;
}
.starA:before, .starA:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0.6em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #df8d33;
  border-left: 1em solid transparent;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}
.starA:after {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}
.select-bot-para {
  position: relative;
  flex-grow: 1;
}
.select-bot-para .threeIconBotaPara {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  text-align: left;
  margin: 0;
  max-width: 500px;
  padding-top: 5px;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
}
.selct-bot-row:hover {
  transition: all 0.3s ease-in-out;
  background: #EFF6FF;
  border: 1px solid #4092FB !important;
}
.selct-bot-row.botTypeDesign.active h4.BotTypeNameTitle {
  color: #000 !important;
}
.rightIconClick>a {
  background-image: url(https://d1c73bf43ozp9u.cloudfront.net/chatbot/wp-content/uploads/2023/02/22065845/Icon-check.png)!important;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 18px 15px;
}
.loaderView {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* first page chatbot */

.createChatBoatStepBy {
  width: 100%;
  min-height: 100vh;
  background: url('../src/bgNew.jpeg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.already-acc {
  position: absolute;
  right: 30px;
  top: 40px;
  margin: 0;
  font-weight: bold;
  color: #000;
  font-size: 16px;
}
.already-acc span {
  color: #1e85fc;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 8px;
}
.logoSectioneasyStep > a > img {
  width: 235px;
  height: auto;
  margin: 10px 0px;
}
.createChatBoatStepBy .centerLavelSteps {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.createChatBoatStepBy h2.stepHeading {
  padding: 0px;
  text-align: center;
  font-weight: normal;
  margin-top: 0px;
  font-size: 45px;
  color: #20303c;
  font-family: 'HelveticaNeueThin';
  margin-top: 5px !important;
}
.createChatBoatStepBy h3.text-center {
  font-size: 12px;
  color: #20303C;
  font-weight: 100;
  font-family: "HelveticaNeueThin";
}
.wrapper {
  width: 90%;
  margin: auto;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  /* height: 100%; */
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  display: block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.createChatBoatStepBy.step4personalitys .swiper-slide {
  background: none;
}
.swiperFormSection .wrapperStep > div {
  position: relative;
  top: 20px;
}

.swiperFormSection .wrapperStep > div {
  margin: 5px auto 20px !important;
}
.botName-view {
  width: 100%;
  margin: 0 auto;
  max-width: 540px;
}
.botName-view input {
  height: 52px;
  width: 100%;
  border-bottom-color: rgb(0, 0, 0);
  border-width: 0px 0px 2px;
  border-top-style: initial;
  border-top-color: initial;
  border-left-style: initial;
  border-left-color: initial;
  border-right-style: initial;
  border-right-color: initial;
  background: rgba(0, 0, 0, 0);
  outline: none;
}
.Loginwarning {
  color: red;
  font-size: 12px;
  margin-top: 20px;
  text-align: left;
}
.Loginwarning > img {
  width: 18px !important;
  display: inline-block !important;
  margin-right: 10px;
  vertical-align: sub;
}
.footerStepbyStep {
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 0px;
}
.review-section {
  width: 1100px;
  margin: auto;
}
.review-heading {
  text-align: center;
  color: #9c9c9e;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  width: 90%;
  margin: 0 auto;
}
.review-heading {
  margin: 100px auto 0;
}
.review-companies {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}
.reviewcompanieswrapper {
  position: relative;
  text-align: center;
  opacity: 0.6;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.reviewcompaniesimg {
  width: 100px;
  height: 30px;
  margin-bottom: 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
}
.reviewcompaniesimg img {
  width: auto;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}
.ratingReview p {
  font-size: 15px;
  font-weight: 100;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}
.reviewratingstar p.tool-tip > span {
  font-size: 20px;
  color: #bfc4c3;
}
.reviewcompanieswrapper::after {
  content: "";
  background: #e6e6e6;
  height: 90%;
  top: 0;
  width: 1px;
  position: absolute;
  left: 140%;
  margin: auto;
  opacity: 0.1;
  bottom: 0;
}

/* Livechat page css */
.loginBG {
  background-image: url('../src/loginBg.png');
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  position: relative;
}
.login-header {
  width: 100%;
  margin: auto;
  padding: 10px 0;
}
.login-header img.loginHeaderLogo {
  width: 235px;
}
.company-information {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.company-information1 {
  width: 750px;
}
.login-description h5 {
  text-align: center;
  margin: 0;
  font-size: 52px;
  font-family: seravek;
  color: #000;
  line-height: 50px;
  font-weight: 400;
}
.company-information1 .login-description h5 {
  font-size: 52px;
  font-family: seravek;
}
.company-information1 .login-description h4 {
  text-align: center;
}
.company-information label {
  margin: 0;
  color: #000;
}
.company-information input {
  background: 0 0;
  border: 0;
  border-bottom: 1.5px solid #000;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 10px 0px;
  font-size: 14px;
  color: #000;
}
.company-information input:focus {
  box-shadow: none;
  background: no-repeat;
  border-color: #000;
  outline: none;
}
.loginFooterReview {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
.catfeacture-section-2 {
  width: 900px;
  margin: auto;
  font-size: 30px;
  text-align: center;
  position: relative;
  bottom: -20px;
}
.clicntLogo-heading {
  font-family: helveticaneuelight;
  font-weight: 700;
}
.clicntLogo-heading strong {
  font-family: helveticaneuebold;
}
.catfeacture-section-2 ul {
  padding-left: 10px;
  padding-top: 10px;
  list-style-type: none;
  display: inline-block;
  margin-bottom: 0;
}
.catfeacture-section-2 ul li {
  float: left;
  padding: 2px 20px;
}
.catfeacture-section-2 ul li img {
  height: 34px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: .7;
}
.company-information span.btn.btn-primary {
  opacity: 0.6;
  cursor: not-allowed;
}




@media (min-width: 1200px){
.container {
    max-width: 1200px;
}
}
@media (min-width: 1441px) {
.selct-bot-row.botTypeDesign {
    padding: 20px;
}
.selct-bot-row.botTypeDesign h4.BotTypeNameTitle {
  font-size: 20px;
}
}

@media (max-width:767px) {
  .review-section {
    width: 100%;
    margin: auto;
}
.createChatBoatStepBy .centerLavelSteps {
  position: relative;
  padding-bottom: 100px;
  top: inherit;
  transform: inherit;
}
}
@media (max-width: 479px){
.createChatBoatStepBy .centerLavelSteps .step1TitleHeadings {
    display: block !important;
    margin-top: 70px;
}
}

@media (min-width: 320px) and (max-width: 480px) {
.createChatBoatStepBy h2.stepHeading {
    margin-top: 30px !important;
    font-size: 22px;
}
.logoSectioneasyStep > a > img {
  width: auto;
  height: 22px;
  margin: 10px 0px 0px 0px;
}
}